<template>
  <div class="newTask">
    <!-- top -->
      <el-breadcrumb separator="/">
            <el-breadcrumb-item >受检管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="back4">检测任务</el-breadcrumb-item>
            <el-breadcrumb-item>任务详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="center">
          <div class="download" style="margin-bottom:24px" @click="downloadTotalReport">下载合计报告</div>
          <span class="blueTitle" style="margin-left:12px;">任务详情</span>
          <div class="create">
              <div class="title">
                <span class="left">任务信息</span>
                <span class="right">
                    <span class="save" slot="reference" @click="edit">编辑</span>
                </span>
              </div>
              <div class="selectBox">
                    <el-descriptions style="margin-left:24px">
                        <el-descriptions-item label="任务名称">{{TaskOkForm.taskName}}</el-descriptions-item>
                        <el-descriptions-item label="任务编号">{{TaskOkForm.taskNo}}</el-descriptions-item>
                        <el-descriptions-item label="机构名称">{{TaskOkForm.orgName}}</el-descriptions-item>
                        <el-descriptions-item label="创建时间">{{TaskOkForm.createTime}}</el-descriptions-item>
                        <el-descriptions-item label="更新时间">{{TaskOkForm.updateTime}}</el-descriptions-item>
                        <el-descriptions-item label="检测时间">{{TaskOkForm.examinationTime}}</el-descriptions-item>
                        <el-descriptions-item label="计划检测数量">{{TaskOkForm.planExamineQty}}</el-descriptions-item>
                        <el-descriptions-item label="真实检测数量">{{TaskOkForm.actualExamineQty}}</el-descriptions-item>
                    </el-descriptions>
              </div>
              <div class="orgList">
                <span class="blueTitle" style="margin-left:24px;">任务详情</span>
                <div class="tableTop">
                    <div class="appactive" @click="appendCheckOrg">添加检测活动</div>
                    <div v-if="!isActiveDelete" class="append" @click="removeActive">删除</div>
                    <div v-if="isActiveDelete" class="noActive">删除</div>
                    <div v-if="!isActiveCancel" class="append" @click="cancelActive">取消</div>
                    <div v-if="isActiveCancel" class="noActive">取消</div>
                    <div v-if="!isActiveEnd" class="append" @click="completeActive">完成</div>
                    <div v-if="isActiveEnd" class="noActive">完成</div>
                
                    <div class="search">
                        <el-input
                          placeholder="请输入内容"
                          prefix-icon="el-icon-search"
                           @change="seachAll"
                          class="elSerch"
                          v-model="allSearch">
                          <template slot="append" style="background-color: #00CCCC">搜索</template>
                        </el-input>
                    </div>
                  </div>
                  <!-- table -->
                  <TaskCheckEventTable :taskId="taskId" ref="TaskCheckEvent"/>
              </div>
          </div>
      </div>
      <!-- 编辑任务详情弹窗 -->
        <el-dialog title="修改任务信息" :visible.sync="editTaskDetail" width="460px">
                <el-form :model="editTaskForm" label-position="right" :rules="rules" ref="editTaskForm">
                      <el-form-item label="任务名称：" prop="taskName" label-width="140px">
                          <el-input v-model="editTaskForm.taskName" autocomplete="off" style="width:220px;"></el-input>
                      </el-form-item>
                      <el-form-item label="计划检测数量：" prop="planExamineQty" label-width="140px">
                          <el-input v-model.number="editTaskForm.planExamineQty" autocomplete="off" style="width:220px;"></el-input>
                      </el-form-item>
                      <el-form-item label="实际检测数量：" prop="actualExamineQty" label-width="140px">
                          <el-input v-model.number="editTaskForm.actualExamineQty" autocomplete="off" style="width:220px;"></el-input>
                      </el-form-item>
                      <el-form-item label="检测机构：" prop="orgId" label-width="140px">
                          <el-select v-model="editTaskForm.orgId" placeholder="请选择机构" style="width:220px;"
                            clearable filterable
                            v-scroll="handleScroll" 
                            size="medium"
                            @focus="slectOrgName">
                              <el-option
                              v-for="(item,index) in orgList2"
                              :key="index"
                              :label="item.orgName"
                              :value="item.id">
                            </el-option>
                        </el-select>
                      </el-form-item>
                </el-form>
                <!-- footer -->
                <div class="footerSub">
                    <div class="tijiao" @click="noEdit">取消</div>
                    <div class="tijiao" @click="isEdit('editTaskForm')">保存</div>
                </div>
        </el-dialog>

      <!-- 添加检测活动弹窗 -->
      <el-dialog
        title="添加检测活动"
        :showClose="false"
        :visible.sync="appendCheck"
        width="390px"
        center>
       <!-- 内容区 -->
        <el-form ref="appendForm" :model="appendForm" label-width="100px">
          <el-form-item label="受检机构">
            <el-select v-model="appendForm.byCheckOrg"
               placeholder="请选择受检机构" style="width:200px !important"
               clearable filterable
               v-scroll="handleScroll" 
               size="medium"
               @focus="slectOrgName">
                  <el-option
                    v-for="(item,index) in byOrgList2"
                    :key="index"
                    :label="item.orgName"
                    :value="item.id">
                  </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="检测类型">
            <el-select v-model="appendForm.checkType"
               placeholder="请选择检测类型" style="width:200px !important"
               clearable filterable
               size="medium"
               @focus="slectOrgName">
                  <el-option
                    v-for="(item,index) in typeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
            </el-select>
          </el-form-item>
          
          <el-form-item label="检测地区">
            <el-cascader v-model="appendForm.checkCity" :options="district" clearable style="width:200px !important"></el-cascader>
          </el-form-item>
          <el-form-item label="检测地点">
            <el-input type="textarea" v-model="appendForm.checkArea" style="width:200px !important"></el-input>
          </el-form-item>
        </el-form>
        <!-- footer -->
        <div style="width:440px;display:flex;justify-content:space-around;margin-top:60px;margin-left:-50px">
          <div style="width:80px;height:32px;font-size:16px;color:#fff;cursor:pointer;background-color: #00cccc;
            line-height: 32px; border-radius:4px;text-align: center" @click="cancelAddDetailTask">取消</div>
          <div style="width:80px;height:32px;font-size:16px;color:#fff;cursor:pointer;background-color: #00cccc;
            line-height: 32px; border-radius:4px;text-align: center" @click="sureAddDetailTask">确认</div>
        </div>
      </el-dialog>
  </div>
</template>
<script>
import TaskCheckEventTable from '@/components/table/taskCheckEventTable.vue'
import { district } from '@/assets/js/cityCode.js'
import {getOrgList} from '@/api/orgController.js'
import {viewCheckTaskListById} from '@/api/checkTask.js'
import {createCheckTaskDetail,updateCheckTaskDetail,cancelCheckTaskDetail,finishCheckTaskDetail} from '@/api/checkTaskDetail.js'
export default {
  beforeRouteLeave(to,from,next){
    if(to.name === 'CheckTask'){
      to.meta.keepAlive = true
    }
    next();
  },
    components:{
      TaskCheckEventTable,
    },
    data(){
      return{
        id:null,//id
        orgId:null,
        isActiveDelete:true,//
        isActiveCancel:true,//
        isActiveEnd:true,//
        activeId:null,//所选框的id
        //cityCode
        district,
        allSearch:null,//模糊查询
        //下拉加载更多
        orgPagination: {
            pageSize: 1000,
            pageNum: 1,
            total: 0
        },
        totalPage: '',
        orgList2:[],
        byOrgList2:[],
        //下拉加载更多结束

        //添加检测活动表单控件值
        appendCheck:false,
        //任务详情form
        TaskOkForm:{
          taskName:'',
          taskNo:'',
          orgName:'',
          createTime:'',
          updateTime:'',
          examinationTime:'',
          planExamineQty:'',
          actualExamineQty:''
        },
         //添加检测活动表单
        appendForm:{
          byCheckOrg:'',// watch
          // byCheckOrgCode:"",
          checkCity:'',
          checkArea:'',
          checkType:null,
        },
        //taskID
        taskId:null,
        //编辑任务详情控件
        editTaskDetail:false,
        //编辑任务详情form
        editTaskForm:{
          taskName:null,
          orgId:null,
          planExamineQty:null,
          actualExamineQty:null,//真实检测数量
        },
        //编辑任务详情校验规则
        rules:{
          taskName:[{ required: true, message: '请填写任务名称', trigger: 'blur' }],
          planExamineQty:[{ type: 'number', required: true, message: '请输入计划检测数量', trigger: 'blur' }],
          orgId:[{ required: true, message: '请选择检测机构', trigger: 'blur' }],
        },
        typeList:[
          {label:'线上',value: '线上'},
          {label:'线下',value: '线下'},
        ]
      }
    },
    created(){
      if(this.$route.query.id){
        this.taskId = this.$route.query.id;
        //任务详情
         this.taskDetailById();
      }
    },
    watch:{
    
    },
    methods:{
      //面包屑事件
    back4(){
        this.$router.go(-1);
    },
      //下载合计报告
      downloadTotalReport(){
          this.$message.error("下载合计报告，待开发！！！");
        },
      //添加检测活动表单控件
      appendCheckOrg(){
          this.appendCheck =true;
           this.slectOrgName();//获取列表机构
        },
      // 滚动加载更多
      handleScroll() {
         //
        },
      //模糊查询
      seachAll(){
          this.$refs.TaskCheckEvent.getData(1,20,this.taskId,this.allSearch);
      },
      // 获取检测机构名称
      slectOrgName() {
          getOrgList({
            orgType:['受检机构','采样机构','采样点'],
            currentPage:this.orgPagination.pageNum,
            pageSize:this.orgPagination.pageSize,
          }).then(res=>{
            if(res.data.status == 200){
              const orgListF =res.data.data.records;
              const newByOrgList2 = [];
              const newOrgList2 = [];
              orgListF.forEach((curr,index)=>{
                  newByOrgList2.push(curr);
              });
              // this.orgList = this.orgList.concat(orgListF);// 是否需要并接，还未知
              this.byOrgList2 = newByOrgList2;
              this.orgList2 = newOrgList2;
              this.totalPage = res.data.data.pages;
              this.orgPagination.total =res.data.data.total;
            }else{
              this.$message.error(res.data.message);
            }
          }).catch(res=>{
             this.$message.error(res);
          })
      },
      //通过id查询具体任务详细
      taskDetailById(){
        viewCheckTaskListById({
          id:this.taskId
        }).then(res=>{
          if(res.data.status ==200){
              //console.log("具体任务信息",res.data.data)
              this.id = res.data.data.id
              this.orgId = res.data.data.orgId
              this.TaskOkForm.taskName =res.data.data.taskName
              this.TaskOkForm.taskNo = res.data.data.taskNo
              this.TaskOkForm.orgName = res.data.data.orgName
              this.TaskOkForm.createTime = res.data.data.createTime
              this.TaskOkForm.updateTime = res.data.data.updateTime
              this.TaskOkForm.examinationTime = res.data.data.examinationTime
              this.TaskOkForm.planExamineQty = res.data.data.planExamineQty
              this.TaskOkForm.actualExamineQty = res.data.data.actualExamineQty
            //
          }else{
            this.$message.error(res.data.message);
          }
        }).catch(res=>{
           this.$message.error(res);
        })
      },
      // 编辑
      edit(){
        this.editTaskDetail = true;
        //回显信息---之后再编辑
        this.editTaskForm.taskName = this.TaskOkForm.taskName;
        this.editTaskForm.orgId = this.TaskOkForm.orgName;
        this.editTaskForm.planExamineQty = this.TaskOkForm.planExamineQty;
      },
      //取消编辑
      noEdit(){
        this.editTaskDetail = false;
        //clear
        for(var el in this.editTaskForm) {
          this.editTaskForm[el] = null;
        };
      },
      //确认编辑
      isEdit(formName){
         this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log("baseData",this.editTaskForm);
            if(this.editTaskForm.orgId == this.TaskOkForm.orgName){
              this.editTaskForm.orgId = this.orgId;
            }   
            updateCheckTaskDetail({
               id:this.id,
              // taskId:this.taskId,
               taskName:this.editTaskForm.taskName,
               orgId:this.editTaskForm.orgId,
               planExamineQty:this.editTaskForm.planExamineQty,
               actualExamineQty:this.editTaskForm.actualExamineQty
            }).then(res=>{
              if(res.data.status == 200){
                 this.$message({
                  message: '修改任务成功！',
                  type: 'success'
                });
                // reload
                this.taskDetailById();
                //clear
                for(var el in this.editTaskForm) {
                  this.editTaskForm[el] = null;
                };
                //close
                this.editTaskDetail = false;
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
            
          } else {
            this.$message.error("必填项不能为空！");
            return false;
          }
        });
      },
      //确认添加检测活动
      sureAddDetailTask(){
        createCheckTaskDetail({
          examinedOrgId:this.appendForm.byCheckOrg,
          provinceCode:this.appendForm.checkCity[0],
          cityCode:this.appendForm.checkCity[1],
          address:this.appendForm.checkArea,
          taskId:this.taskId,
          type:this.appendForm.checkType
          }).then(res=>{
            if(res.data.status == 200){
              this.$message({
                    message: '添加检测活动成功！',
                    type: 'success'
                  });
                //fresh
                this.$refs.TaskCheckEvent.getData(1,40,this.taskId,null);
                this.appendCheck = false;
            }else{
              this.$message.error(res.data.message);
            }
          }).catch(res=>{
             this.$message.error(res);
          })
      },
      //取消添加检测活动
      cancelAddDetailTask(){
        for(var el in this.appendForm) {
            this.appendForm[el] = "";
        }
        this.appendCheck = false;
      },


      //表尾-------------------------------------------------------------
      removeActive(){//移除检测活动
          this.$message.error("暂不支持删除");
      },
      cancelActive(){//取消检测活动
         var sta = this.activeId.every((curr,index)=>{
            console.log(curr.status)
            return (curr.status == "OPEN")
          });
          if(sta){
            var temId = [];
            this.activeId.forEach((cur,index)=>{
              temId.push(cur.id)
            });
            //console.log("tem",temId);
            cancelCheckTaskDetail({
              ids:temId
            }).then(res=>{
              if(res.data.status == 200){
                  this.$message({
                    message:res.data.message,
                    type: 'success'
                });
                this.$refs.TaskCheckEvent.getData(1,40,this.taskId,null);//刷新
              }else{
                  this.$message.error(res.data.message);
              }
            })

          }
      },
      completeActive(){//检测状态的完成
           var sta = this.activeId.every((curr,index)=>{
            return (curr.status == "PROCESSING")
          });
          if(sta){
            var temIds = [];
            this.activeId.forEach((cur,index)=>{
              temIds.push(cur.id)
            });
            //console.log("tem",temId);
            finishCheckTaskDetail({
              ids:temIds
            }).then(res=>{
              if(res.data.status == 200){
                  this.$message({
                    message:res.data.message,
                    type: 'success'
                });
                this.$refs.TaskCheckEvent.getData(1,10,this.taskId);//刷新
              }else{
                  this.$message.error(res.data.message);
              }
            })

          }
      },
  }
}
</script>

<style  scoped lang="stylus">
//change elemen-ui style
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
/deep/.el-button{
    padding: 0px 0 0 4px !important;
    text-align: center !important;
    color: #fff !important;
    font-size: 14px !important;
  }
/deep/.el-popconfirm__action .el-button:nth-child(1) {
    background: #409eff;
  }
/deep/.el-popconfirm__action .el-button:nth-child(2) {
    background: #409eff;
  }
//搜索框样式--边框颜色
.elSerch>>>.el-input__inner
    border: 1px solid #00cccc !important
    width: 200px
    border-radius: 4px 0 0 4px
//搜索框样式--高度
/deep/.el-input__inner{
    height: 32px;
  }
//搜索框样式--搜索部分样式
/deep/.el-input-group__append{
    border-block-color:#00CCCC !important;
    color: #fff !important;
    background-color: #00CCCC !important;
    border: 0 !important;
  }

//normal
.noActive
  width: 116px
  height: 32px
  background-color: #f4f4f4
  font-size:14px 
  line-height: 32px 
  text-align: center
  color: #AEAEAE
  border-radius: 2px 
  cursor not-allowed
  margin-right: 24px

//comstyle 
.center
  overflow hidden
  width: 100%
  margin-top: 24px 
  .download
    width: 116px
    height: 32px
    background-color: #00CCCC
    font-size:14px 
    font-family: Source Han Sans CN
    line-height: 32px 
    text-align: center
    color: #fff
    border-radius: 2px 
    cursor pointer
  .blueTitle
    font-size: 16px 
    color: #353535
    line-height: 28px 
    font-weight: bold 
    position: relative
    &::before
      width: 3px
      height: 18px
      content: ""
      position: absolute
      border-radius: .03rem
      left: -10px
      bottom:1px
      background-color: #00CCCC
  .create
    padding: 20px
    box-sizing: border-box
    margin-top: 24px
    .title
      width: 100%
      height: 32px 
      background-color: #00cccc
      display: flex
      justify-content: space-between
      align-items: center
      .left 
        margin-left: 20px
        font-family: Source Han Sans CN
        font-size: 16px
        color: #fff
      .right
        margin-right: 20px 
        .cancel 
          margin-right: 16px 
          font-family: Source Han Sans CN
          font-size: 16px
          color: #fff
          cursor pointer
        .save
          font-family: Source Han Sans CN
          font-size: 16px
          cursor pointer
          color: #fff
    .selectBox
      width: 100%
      padding: 24px 0
      background-color: #fff

    .orgList
      width: 100%
      background-color: #fff
      margin-top:30px 
      .tableTop
        display: flex
        align-items: center
        justify-content: space-between
        margin-top: 16px
        .appactive
          width: 116px
          height: 32px
          background-color: #00CCCC
          font-size:14px 
          line-height: 32px 
          text-align: center
          color: #fff
          border-radius: 2px 
          cursor pointer
          margin-right: 24px
          width: 116px
          height: 32px
          background-color: #00CCCC
          font-size:14px 
          line-height: 32px 
          text-align: center
          color: #fff
          border-radius: 2px 
          cursor pointer
          margin-right: 24px
        .append
          width: 116px
          height: 32px
          background-color: #00CCCC
          font-size:14px 
          line-height: 32px 
          text-align: center
          color: #fff
          border-radius: 2px 
          cursor pointer
          margin-right: 24px
        .search
          height: 32px
         

.footerSub
    width: 400px
    margin: 0 auto
    display: flex
    justify-content: space-evenly
    .tijiao
        width:80px
        height:32px
        font-size:16px
        color:#fff;cursor:pointer
        background-color: #00cccc
        line-height: 32px
        border-radius:4px
        text-align: center

</style>