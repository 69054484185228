// 检测任务详情 
import  request  from "@/utils/request";

// 搜检管理-检测任务-检测任务详情-查询检测任务详情列表（查询检测活动列表）
export function getCheckTaskDetailList (parameter){
    return request({
       url:'/api/examine-task-detail/list',
       method:'post',
       data:parameter
   })
}

// 搜检管理-检测任务-检测任务详情-更新检测任务详情
export function updateCheckTaskDetail (parameter){
    return request({
       url:'/api/examine-task/update',
       method:'post',
       data:parameter
   })
}

// 搜检管理-检测任务-检测任务详情-新增检测任务详情(新增检测活动)
export function createCheckTaskDetail (parameter){
    return request({
       url:'/api/examine-task-detail/insert',
       method:'post',
       data:parameter
   })
}

// 搜检管理-检测任务-检测任务详情-取消检测任务详情（检测活动）
export function cancelCheckTaskDetail (parameter){
    return request({
       url:'/api/examine-task-detail/cancel',
       method:'post',
       data:parameter
   })
}

// 搜检管理-检测任务-检测任务详情-结束检测任务详情（检测活动）
export function finishCheckTaskDetail (parameter){
    return request({
       url:'/api/examine-task-detail/finish',
       method:'post',
       data:parameter
   })
}

// 
// 搜检管理-检测任务-检测任务详情-检测任务详情基本信息(查询检测活动具体信息)
export function viewTaskInfo (parameter){
    return request({
       url:'/api/examine-task-detail/viewExamineTaskDetail',
       method:'post',
       data:parameter
   })
}

//受检管理/检测任务/任务详情/单条任务列表-- 下载专业报告
export function getProReport (parameter){
    return request({
    headers: {
        'content-type': 'application/json;charset=utf-8',
        },
    responseType: 'blob',
    url:'/api/report-info/downPdfReport',
    method:'post',
    data:parameter
})
}

//受检管理/检测任务/任务详情/单条任务列表-- 下载脊柱原始数据
export function getSpineData (parameter){
    return request({
    headers: {
        'content-type': 'application/json;charset=utf-8',
        },
    responseType: 'blob',
    url:'/api/report-info/downSpineReportData',
    method:'post',
    data:parameter
})
}

//受检管理/检测任务/任务详情/单条任务列表-- 下载足部原始数据
export function getFootData (parameter){
    return request({
    headers: {
        'content-type': 'application/json;charset=utf-8',
        },
    responseType: 'blob',
    url:'/api/report-info/downFootReportData',
    method:'post',
    data:parameter
})
}