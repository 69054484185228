// 搜检管理-检测任务
import  request  from "@/utils/request";

// 搜检管理-检测任务-查询检测任务
export function getCheckTaskList (parameter){
    return request({
       url:'/api/examine-task/list',
       method:'post',
       data:parameter
   })
}
// 搜检管理-检测任务-查询检测任务(单条查询-id)
export function viewCheckTaskListById (parameter){
    return request({
       url:'/api/examine-task/view',
       method:'post',
       data:parameter
   })
}

// 搜检管理-检测任务-更改检测任务
export function updateCheckTask (parameter){
    return request({
       url:'/api/examine-task/update',
       method:'post',
       data:parameter
   })
}

// 搜检管理-检测任务-创建检测任务
export function createCheckTask (parameter){
    return request({
       url:'/api/examine-task/insert',
       method:'post',
       data:parameter
   })
}

// 搜检管理-检测任务-取消任务-open状态的任务才能取消
export function cancelTask (parameter){
    return request({
       url:'/api/examine-task/cancel',
       method:'post',
       data:parameter
   })
}

// 搜检管理-检测任务-结束任务-procession状态的任务才能结束
export function finishTask (parameter){
    return request({
       url:'/api/examine-task/finish',
       method:'post',
       data:parameter
   })
}



