<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    @selection-change="selectRow"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
     type="selection"
     fill="#00CCCC"
     fixed="left">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      prop="examinedOrgName"
      label="受检机构"
      show-overflow-tooltip
      width="170"
      align="center">
    </el-table-column>
    <el-table-column
      prop="taskDetailNo"
      label="任务单号"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      label="检测地区"
      align="center"
      show-overflow-tooltip>
      <template slot-scope="scope">{{ scope.row.provinceName }}{{ scope.row.cityName }}</template>
    </el-table-column>
    <el-table-column
      prop="type"
      label="检测类型"
      align="center"
      >
    </el-table-column>
    <el-table-column
      prop="address"
      label="检测地点"
      align="center"
      show-overflow-tooltip>
    </el-table-column>
    <el-table-column
      prop="status"
      align="center"
      label="检测状态"
      show-overflow-tooltip>
    </el-table-column>
     <el-table-column
      align="center"
      label="操作"
      width="120">
      <template slot-scope="scope">
        <span style="color:#00cccc;cursor: pointer;" @click="clickRow(scope.row)">查看详情</span>
      </template>
    </el-table-column>
  </el-table>
  <!-- 分页 -->
  <div class="page">
  <!-- left -->
  <span class="pageLeft">共 {{count}} 条记录 第 {{currentPage}} / {{tatalPage}}页</span>
  <!-- right -->
  <el-pagination
      @size-change="SizeChange"
      background
      @current-change="CurrentChange"
      @prev-click="prevClick"
      @next-click="nextClick"
      :current-page="currentPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="onlyPage"
      :pager-count="5"
      layout="sizes, prev, pager, next, jumper"
      :total="count">
    </el-pagination>
  </div>
</div>
</template>

<script>
import {getCheckTaskDetailList} from '@/api/checkTaskDetail.js'
  export default {
    data() {
      return {
        tableData: [],//表格数据
        multipleSelection: [],// tableCheckboxValue
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:40,// 当前页面可以展示多少条数据,默认10 
      }
    },
    props:['taskId'],
    created(){
      this.getData(1,40,this.taskId,null,null); //暂时先不去请求数据
    },

    //监听勾选值
    watch:{
        multipleSelection:{
            handler(pre){
              var tem = pre;
              if(tem.length==0){
                 this.$parent.isActiveDelete = true;
                 this.$parent.isActiveCancel = true;
                 this.$parent.isActiveEnd = true;
              }
              if(tem.length >=1){
                this.$parent.activeId = tem;
                this.$parent.isActiveDelete = false;
                var staCancel = tem.every((curr)=>{
                  return (curr.status == "OPEN")
                });
                var staEnd = tem.every((curr)=>{
                  return (curr.status == "PROCESSING")
                });
                
                if(staCancel){
                  this.$parent.isActiveCancel = false;
                }else{
                  this.$parent.isActiveCancel = true;
                }

                 if(staEnd){
                  this.$parent.isActiveEnd = false;
                }else{
                  this.$parent.isActiveEnd = true;
                }

               }
             
            },
            deep:true,
            immediate:true
        }
    },
    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(currentPage,onlyPage,taskId,examinedOrgName){
              const rLoading = this.openLoading();// 开始loading
              getCheckTaskDetailList({
                currentPage: currentPage,
                pageSize: onlyPage,
                taskId:taskId,
                examinedOrgName:examinedOrgName
            }).then((res)=>{
              if(res.data.status == 200){
               
                this.tableData = res.data.data.records;
                this.tatalPage =parseInt(res.data.data.size);
                this.count =parseInt(res.data.data.total);
                rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 复选框选项
      selectRow(val) {
        this.multipleSelection.length = 0;//clear
        this.multipleSelection = val;
      },
      
      // 分页相关
      SizeChange(val){
        this.onlyPage = val;
        this.getData(this.currentPage,this.onlyPage,this.taskId,this.$parent.allSearch);//更新数据
      },
      CurrentChange(val){
        this.currentPage = val;
        this.getData(this.currentPage,this.onlyPage,this.taskId,this.$parent.allSearch);//更新数据
      },
      prevClick(val){
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.taskId,this.$parent.allSearch);//更新数据
      },
      nextClick(val){
        this.currentPage = val ;
        this.getData(this.currentPage,this.onlyPage,this.taskId,this.$parent.allSearch);//更新数据
      },

      //查看详情
      clickRow(e){
         // console.log(e);
          this.$router.push({
                path:'/checkTask/taskDetails/byCheckDetail',
                query:{
                   id:e.id,

                }
          })
      }
    },
    computed:{
      
    }
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
   
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
  margin: 0 !important;
}
.resoult{
  text-align: left;
}
.page{
  display: flex;
  margin-top: .3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft{
  color: #858585;
  font-size: .1094rem;
  line-height: .1875rem;
}
.el-pagination{
  color: #858585 !important;
  font-size: .1094rem !important;
  line-height: .1875rem !important;
}
</style>